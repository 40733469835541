<template>
    <div class="Studentpassword">
        <div class="Student_div">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
                <el-form-item label="手机号码" prop="phoneNumber">
                    <el-input v-model="ruleForm.phoneNumber" disabled></el-input>
                </el-form-item>
                <el-form-item label="旧密码" prop="password">
                    <el-input type="password" v-model="ruleForm.password" disabled></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newpw">
                    <el-input type="password" v-model="ruleForm.newpw" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checknewpw">
                    <el-input type="password" v-model="ruleForm.checknewpw" placeholder="请再次输入密码"></el-input>
                </el-form-item>
            </el-form>
            <span class="Student_span">
                <el-button type="primary" @click="addqued('ruleForm')">确 定</el-button>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码'));
            } else if(!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,}$/.test(value)) {
                callback('密码为8-16位字母、数字或符号至少2种组合')
            }else{
                if (this.ruleForm.checknewpw !== '') {
                    this.$refs.ruleForm.validateField('checknewpw');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.newpw) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            ruleForm:{
                phoneNumber:'',
                password:'',
                newpw:'',
                checknewpw:'',
            },
            rules:{
                newpw: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checknewpw: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
            },
        }
    },
    methods: {
        getapp(){
            this.axios.gadminetInfo({
                params:{
                    adId:this.$store.state.uid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.ruleForm = res.data.data
                }
            }).catch(err=>{

            })
        },
        // 确认
        addqued(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.adminedit({
                        adId:this.$store.state.uid,
                        password:this.ruleForm.newpw
                    }).then(res=>{
                        if(res.data.code==200){
                            this.$message.success('修改成功')
                            this.$refs[formName].resetFields();
                            this.getapp()
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
    },
    mounted () {
      this.getapp()  
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Studentpassword.css';
</style>